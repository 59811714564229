import _ from 'lodash';
import { getCompanyAllHomeLayouts, getCompanyAllPagesInfo, getPageHomeLayout } from './apiRequests/witmegRetailPaxServerRequests';
import config from "react-global-configuration";

export const RESTAURANT_DATA_CONFIG = {
  ORDER_TYPES: {
    DINE_IN: {
      name: 'table',
      priceName: 'TablePrice',
    },
    DELIVERY: {
      name: 'delivery',
      priceName: 'DeliveryPrice',
    },
    COLLECTION: {
      name: 'collection',
      priceName: 'TakeAwayPrice',
    },
  },
};

export const getOrderType = (orderType) => {
  let typeOfOrder = ""
  let tableNumber = ""
  const pathName = window.location.pathname
  const orderTypeString = pathName.split("/")[4]
  const cloudLocationId = pathName.split("/")[3]
  const organizationId = pathName.split("/")[2]

  if (orderTypeString) {
    if (orderTypeString.includes("yHBTzJCEiV50QAOhSEFWdiDY8ScBDceP+65d6dfd4452ece9397f01637"))
      typeOfOrder = "takeaway"
    if (orderTypeString.includes("yHBTzJCEiV50QAOhSEFWdiDY8ScBDceP+65d6e020452ece9397f01639"))
      typeOfOrder = "delivery"
    if (orderTypeString.includes("W44Ztd7G49v41GA9cwKQtZcDTzzXAI6C+65d6df91b4486d9ff2f5ee52")) {
      typeOfOrder = "table"
      tableNumber = Number(orderTypeString.split("+")[2])
    }
  }
  else {
    typeOfOrder = orderType
  }
  return { orderType: typeOfOrder, tableNumber, cloudLocationId, organizationId }
}
export const getRestaurantMenuProductPrice = (data, _orderType, _provider = 'Restaurant') => {
  if (!data || !_orderType) return 0;

  let _productData
  let isDealData = false
  if (data.ItemID && data.ItemID !== "") {
    _productData = data.ProductData
  }
  if (data.ProductDealID && data.ProductDealID !== "") {
    _productData = data.ProductDealData
    isDealData = true
  }

  const { orderType, tableNumber } = getOrderType(_orderType)
  // const pathName = window.location.pathname
  // const orderTypeString = pathName.split("/")[4]

  // let typeOfOrder = ""
  // let tableNumber = ""
  // if (orderTypeString) {
  //   if (orderTypeString.includes("yHBTzJCEiV50QAOhSEFWdiDY8ScBDceP+65d6dfd4452ece9397f01637"))
  //     typeOfOrder = "collection"
  //   if (orderTypeString.includes("yHBTzJCEiV50QAOhSEFWdiDY8ScBDceP+65d6e020452ece9397f01639"))
  //     typeOfOrder = "delivery"
  //   if (orderTypeString.includes("W44Ztd7G49v41GA9cwKQtZcDTzzXAI6C+65d6df91b4486d9ff2f5ee52")) {
  //     typeOfOrder = "table"
  //     tableNumber = orderTypeString.split("+")[2]
  //   }
  // }
  // else {
  //   typeOfOrder = _orderType
  // }



  // +61/632983961250b0105020d561
  const { DINE_IN, DELIVERY, COLLECTION } = RESTAURANT_DATA_CONFIG.ORDER_TYPES;

  let _isUnitChartItem = false;
  if (_productData.UnitChartInfo && _productData.UnitChartInfo.length > 0) _isUnitChartItem = true;

  if (_isUnitChartItem) {
    let __unitChartItemPrices = [];

    for (let __unitChartItem of _productData.UnitChartInfo) {
      const ___itemPrices = _.find(__unitChartItem.ServicePriceInfo, { ServiceProvider: _provider });

      if (orderType === DINE_IN.name) __unitChartItemPrices.push(___itemPrices[DINE_IN.priceName] ?? 0);
      if (orderType === DELIVERY.name) __unitChartItemPrices.push(___itemPrices[DELIVERY.priceName] ?? 0);
      if (orderType === COLLECTION.name) __unitChartItemPrices.push(___itemPrices[COLLECTION.priceName] ?? 0);
    }

    return Math.min(...__unitChartItemPrices);

  } else {
    if (!isDealData)
      if (!_productData.ServicePriceInfo.Price || _productData.ServicePriceInfo.Price.length === 0) return 0;

    if (isDealData)
      if (!_productData.ServicePriceInfo || _productData.ServicePriceInfo.length === 0) return 0;

    let __itemPrice
    if (!isDealData)
      __itemPrice = _.find(_productData.ServicePriceInfo.Price, { ServiceProvider: _provider });
    else
      __itemPrice = _.find(_productData.ServicePriceInfo, { ServiceProvider: _provider });

    console.log('Item Price', __itemPrice)
    if (orderType === DINE_IN.name) return __itemPrice[DINE_IN.priceName] ?? 0;
    if (orderType === DELIVERY.name) return __itemPrice[DELIVERY.priceName] ?? 0;
    if (orderType === COLLECTION.name) return __itemPrice[COLLECTION.priceName] ?? 0;
  }

  return 0;
}

export const LOCAL_STORAGE_ITEMS_NAMES = {
  ECOM_CART_DATA: '__eco_rp_eucd',
  ECOM_CUSTOMER_DETAILS: '__eco_rp_ecd',
  ECOM_RESTAURANT_MENU_CURRENCY: '__eco_rp_rmc',
  ECOM_RESTAURANT_MENU_ORDER_TYPES: '__eco_rmot',
  ECOM_RESTAURANT_ALL_MENU_DATA: '__eco_ramd',
  ECOM_RESTAURANT_CART_DATA: '__eco_rcd',
  ECOM_RESTAURANT_CART_ORDER_ID: '__eco_rc_oid',
  ECOM_RESTAURANT_ORDER: '__eco_rc_ord',
  ECOM_RESTAURANT_USER_ID: '__eco_rc_cui',
  ECOM_RESTAURANT_DATA: '__eco_rd',
  ECOM_RESTAURANT_TRACK_ORDER: '__eco_rto',
  ECOM_VOUCHER_DATA: '__eco_vd',
  ECOM_SELECTED_LOC: '__eco_s_loc',

};

export const getLocalStorageData = async (propertyName, isJSON = true) => {
  let propertyValue = await localStorage.getItem(propertyName);

  if (!propertyValue) return null;

  if (isJSON) return JSON.parse(propertyValue);
  else return propertyValue;
};

export const setLocalStorageData = async (propertyName, propertyValue, isJSON = true) => {
  const data = isJSON ? JSON.stringify(propertyValue) : propertyValue;
  await localStorage.setItem(propertyName, data);
}

export const getCurrencyFormat = (_type = 'GBP') => (_value, _withSpace = false) => {
  const _formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: _type.toUpperCase() });
  const _newValue = _formatter.format(_value);

  if (_withSpace) return _formatter.format(_value).replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ');

  return _newValue;
};

export const isECommerceProcessPath = () => {
  const _validPaths = ["checkout", 'order-summary', 'payment-details', 'order-success'];

  const _path = window.location.pathname.split('/');

  if (!_path[1] || _path[1] === "" || !_validPaths.includes(_path[1])) return false;

  return true;
}

export const getRestaurantMenuAvailablePath = async () => {
  const _response = await getCompanyAllHomeLayouts({
    ID: "",
    PageID: "",
    CompanyID: config.get("companyID"),
    Start: 0,
    Limit: 1000,
  });

  const _restaurantMenuPage = _.find(_response.Desktop, { Item: "resturant menu" });

  if (!_restaurantMenuPage || (_restaurantMenuPage && _restaurantMenuPage.PageID === "000000000000000000000000")) {
    return '/';
  } else {
    const _pagesResponse = await getCompanyAllPagesInfo({
      ID: "",
      CompanyID: config.get('companyID')
    });
    const _slug = _.find(_pagesResponse, { ID: _restaurantMenuPage.PageID }).Slug;
    return `/pages/${_slug}`;
  }
}