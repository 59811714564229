import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import "./VoucherDefaultOrderPage.scss"

import "@fontsource/inter";
import OrderConfirmationPage from './VoucherDefaultOrderConfirmationPage';
import { LOCAL_STORAGE_ITEMS_NAMES } from '../../../RestaurantWidget/utilities/helper/helper';

const VoucherDefaultOrderPage = () => {

    let history = useHistory();
    const orderID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) : ""


    // useEffect(() => {
    //     if (orderID === "") {
    //         history.push("/")
    //     }
    // }, [])


    return (
        <div className='VoucherDefaultOrderPage'>  <OrderConfirmationPage />
        </div>
    )
}

export default VoucherDefaultOrderPage