import React, { useState } from "react";
import "./HospitalityAuth.scss"
import { InputBox, Label } from 'witmeg-ui-system'
import { Select } from "antd";
import { useHistory } from 'react-router'
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useSelector } from 'react-redux';
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
import Modal from "../../components/Modal/Modal";

const { Option } = Select;

const HospitalityCreateAccount = () => {
    let history = useHistory();
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [addressType, setAddressType] = useState("")
    const [address1, setAddress1] = useState("")
    const [address2, setAddress2] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [country, setCountry] = useState("")
    const [phone, setPhone] = useState("")
    const [landline, setLandline] = useState("")
    const [errors, setErrors] = useState({
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        userName: "",
        confirmPassword: "",
        addressType: "",
        address1: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        phone: ""
    })
    const [isError, setIsError] = useState(false)
    const [isError2, setIsError2] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const companyDetails = useSelector((state) => state.companyData.companyDetails);

    const validateForm = () => {
        let valid = true;
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const newErrors = {
            password: "",
            email: "",
            firstName: "",
            lastName: "",
            userName: "",
            confirmPassword: "",
            addressType: "",
            address1: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
            phone: ""
        };
        if (password.trim() === "") {
            newErrors.password = "*required";
            valid = false;
        }
        if (!passwordRegex.test(password)) {
            newErrors.password = "Password must be at least 8 characters long, include one uppercase letter, one number, and one special character.";
            valid = false;
        }
        if (firstName.trim() === "") {
            newErrors.firstName = "*required";
            valid = false;
        }
        if (lastName.trim() === "") {
            newErrors.lastName = "*required";
            valid = false;
        }
        if (userName.trim() === "") {
            newErrors.userName = "*required";
            valid = false;
        }
        if (confirmPassword.trim() === "") {
            newErrors.confirmPassword = "*required";
            valid = false;
        }
        if (confirmPassword !== password) {
            newErrors.password = "Password doesn't match";
            valid = false;
        }
        if (addressType.trim() === "") {
            newErrors.addressType = "*required";
            valid = false;
        }
        if (address1.trim() === "") {
            newErrors.address1 = "*required";
            valid = false;
        }
        if (city.trim() === "") {
            newErrors.city = "*required";
            valid = false;
        }
        if (state.trim() === "") {
            newErrors.state = "*required";
            valid = false;
        }
        if (country.trim() === "") {
            newErrors.country = "*required";
            valid = false;
        }
        if (postalCode.trim() === "") {
            newErrors.postalCode = "*required";
            valid = false;
        }
        if (phone.trim() === "") {
            newErrors.phone = "*required";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleChange = (name) => {
        setErrors({
            ...errors,
            [name]: "",
        });
    };

    const handleRegister = () => {

        var secondAddresss = {
            FirstName: firstName,
            LastName: lastName,
            UserName: userName,
            Password: password,
            Email: sessionStorage.getItem("tmpEmail"),
            Avatar: "",
            OriginatedOrganizationID: config.get("OrganisationID"),
            Addresses: [
                {
                    AddressType: addressType,
                    AddressLine1: address1,
                    AddressLine2: address2,
                    City: city,
                    StateProvinceRegion: state,
                    PostZipCode: postalCode,
                    Country: country,
                    Phone: phone,
                    Fax: landline,
                    Email: "",
                    CreatedDate: Date.now,
                    LastModifiedDate: Date.now,
                },
            ],
        };
        const requestOptions = {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + config.get("LoginAuthorizationKey"),
            },
            body: JSON.stringify(secondAddresss),
        };

        const requestEUserDtlsOptions = {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + config.get("LoginAuthorizationKey"),
            },
            body: JSON.stringify({
                Email: sessionStorage.getItem("tmpEmail").toLowerCase(),
                OrganizationID: config.get("OrganisationID"),
            }),
        };

        fetch(config.get("API_URL_cust") + "globalfind", requestEUserDtlsOptions)
            .then((response) => response.json())
            .then(function (eudata) {

                if (eudata.Status) {

                    var secondAddresss = {
                        FirstName: firstName,
                        LastName: lastName,
                        UserName: userName,
                        Password: password,
                        Email: sessionStorage.getItem("tmpEmail"),
                        Avatar: "",
                        UserID: eudata.Result,
                        OriginatedOrganizationID: config.get("OrganisationID"),
                        Addresses: [
                            {
                                AddressType: addressType,
                                AddressLine1: address1,
                                AddressLine2: address2,
                                City: city,
                                StateProvinceRegion: state,
                                PostZipCode: postalCode,
                                Country: country,
                                Phone: phone,
                                Fax: landline,
                                Email: "",
                                CreatedDate: Date.now,
                                LastModifiedDate: Date.now,
                            },
                        ],
                    };
                    const requestJoinOptions = {
                        method: "POST",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + config.get("LoginAuthorizationKey"),
                        },
                        body: JSON.stringify(secondAddresss),
                    };

                    fetch(config.get("API_URL_cust") + "join", requestJoinOptions)
                        .then((response) => response.json())
                        .then(function (data2) {
                            sessionStorage.setItem("AccessToken", data2.Result.AccessToken);
                            sessionStorage.setItem(
                                "RefreshToken",
                                data2.Result.RefreshToken
                            );
                            localStorage.setItem("isLogged", "true")
                            const expireTime =
                                new Date().getTime() + config.get("CustjwtExp") * 1000;

                            var payload = {
                                aud: config.get("CustjwtAud"),
                                exp: expireTime,
                                iss: config.get("CustjwtIss"),
                                usertoken: data2.Result.AccessToken,
                            };

                            var signOptions = {
                                algorithm: "RS256",
                            };

                            var privateKey = config.get("LoginprivateKey");
                            var token = jwt.sign(payload, privateKey, signOptions);

                            const requestUserDtlsOptions = {
                                method: "POST",
                                headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ` + token,
                                },
                                body: JSON.stringify({
                                    Email: sessionStorage.getItem("tmpEmail").toLowerCase(),
                                    OrganizationID: config.get("OrganisationID"),
                                }),
                            };

                            fetch(
                                config.get("API_URL_cust") + "fetch",
                                requestUserDtlsOptions
                            )
                                .then((response) => response.json())
                                .then(function (data) {

                                    sessionStorage.setItem("username", data.Result.UserName);
                                    sessionStorage.setItem(
                                        "userfirstname",
                                        data.Result.FirstName
                                    );
                                    sessionStorage.setItem(
                                        "userlastname",
                                        data.Result.LastName
                                    );
                                    sessionStorage.setItem("useremail", userName);
                                    sessionStorage.setItem("UserID", data.Result.UserID);
                                    sessionStorage.setItem(
                                        "OriginatedOrganizationID",
                                        data.Result.OriginatedOrganizationID
                                    );
                                    localStorage.setItem("isLogged", "true")
                                    localStorage.setItem(
                                        "fullUserDetails",
                                        JSON.stringify(data.Result)
                                    );
                                    setIsLoading(false)
                                    setIsSuccess(true)
                                });
                        });
                } else {
                    fetch(config.get("API_URL_cust") + "add", requestOptions)
                        .then((response) => response.json())
                        .then(function (data) {

                            if (data.Status === false) {
                                setIsLoading(false)
                                setIsError(true)
                            } else if (
                                data.Result ==
                                "Customer Already Exist in Global. Cannot insert again"
                            ) {
                                setIsLoading(false)
                                setIsError2(true)
                            } else {
                                sessionStorage.setItem(
                                    "AccessToken",
                                    data.Result.AccessToken
                                );
                                sessionStorage.setItem(
                                    "RefreshToken",
                                    data.Result.RefreshToken
                                );
                                sessionStorage.setItem("loggedin", "true");
                                localStorage.setItem("isLogged", "true")
                                const expireTime =
                                    new Date().getTime() + config.get("CustjwtExp") * 1000;

                                var payload = {
                                    aud: config.get("CustjwtAud"),
                                    exp: expireTime,
                                    iss: config.get("CustjwtIss"),
                                    usertoken: data.Result.AccessToken,
                                };

                                var signOptions = {
                                    algorithm: "RS256",
                                };

                                var privateKey = config.get("LoginprivateKey");
                                var token = jwt.sign(payload, privateKey, signOptions);

                                const requestUserDtlsOptions = {
                                    method: "POST",
                                    headers: {
                                        "Access-Control-Allow-Origin": "*",
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ` + token,
                                    },
                                    body: JSON.stringify({
                                        Email: sessionStorage.getItem("tmpEmail").toLowerCase(),
                                        OrganizationID: config.get("OrganisationID"),
                                    }),
                                };

                                fetch(
                                    config.get("API_URL_cust") + "fetch",
                                    requestUserDtlsOptions
                                )
                                    .then((response) => response.json())
                                    .then(function (data) {

                                        sessionStorage.setItem("username", data.Result.UserName);
                                        sessionStorage.setItem(
                                            "userfirstname",
                                            data.Result.FirstName
                                        );
                                        sessionStorage.setItem(
                                            "userlastname",
                                            data.Result.LastName
                                        );
                                        sessionStorage.setItem("useremail", userName);
                                        sessionStorage.setItem("UserID", data.Result.UserID);
                                        sessionStorage.setItem(
                                            "OriginatedOrganizationID",
                                            data.Result.OriginatedOrganizationID
                                        );
                                        localStorage.setItem("isLogged", "true")

                                        localStorage.setItem(
                                            "fullUserDetails",
                                            JSON.stringify(data.Result)
                                        );
                                        setIsLoading(false)
                                        setIsSuccess(true)

                                    });
                            }
                        });
                }
            });

    };
    return (
        <div className="HospitalityAuth">
            <div className="HospitalityAuth-inner">
                <div className="header-text-cont">Create an Account</div>
                <div className="auth-container-wrapper">
                    <div className="auth-container-wrapper-left">
                        <div className="auth-container-left-inner">
                            <div className="form-container">
                                <div className="form-header">Name</div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">First Name</Label></div>
                                        <InputBox onChange={(e) => {
                                            setFirstName(e.target.value);
                                            handleChange("firstName")
                                        }} type="text" value={firstName} error={errors.firstName} />
                                        <div className="error-text">{errors.firstName}</div>

                                    </div> <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Last Name</Label></div>
                                        <InputBox onChange={(e) => {
                                            setLastName(e.target.value);
                                            handleChange("lastName")
                                        }} type="text" value={lastName} error={errors.lastName} />
                                        <div className="error-text">{errors.lastName}</div>

                                    </div>
                                </div>
                                <div className="form-header">Email Login information</div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Email</Label></div>
                                        <div className="input-empty">{sessionStorage.getItem("tmpEmail")}</div>
                                    </div> <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">User Name</Label></div>
                                        <InputBox onChange={(e) => {
                                            setUserName(e.target.value);
                                            handleChange("userName")
                                        }} type="text" value={userName} error={errors.userName} />
                                        <div className="error-text">{errors.userName}</div>

                                    </div>
                                </div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Password</Label></div>
                                        <InputBox onChange={(e) => {
                                            setPassword(e.target.value);
                                            handleChange("password")
                                        }} type="password" value={password} error={errors.password} />
                                        <div className="error-text">{errors.password}</div>


                                    </div> <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Confirm Password</Label></div>
                                        <InputBox onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                            handleChange("confirmPassword")
                                        }} type="password" value={confirmPassword} error={errors.confirmPassword} />
                                        <div className="error-text">{errors.confirmPassword}</div>

                                    </div>
                                </div>
                                <div className="form-header">Address Information</div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Address Type</Label></div>
                                        <Select defaultValue='' onChange={(e) => {
                                            setAddressType(e);
                                            handleChange("addressType")
                                        }} className="select">
                                            <Option value='' >
                                                Please Select
                                            </Option>
                                            <Option value='Billing'>
                                                Billing
                                            </Option>
                                            <Option value='Delivery'>
                                                Delivery
                                            </Option>
                                            <Option value='Home'>Home</Option>
                                            <Option value='Other'>Other</Option>
                                        </Select>
                                        <div className="error-text" style={{ marginTop: "8px" }}>{errors.addressType}</div>

                                    </div> <div className="input-containers " />
                                </div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Address 1</Label></div>
                                        <InputBox onChange={(e) => {
                                            setAddress1(e.target.value);
                                            handleChange("address1")
                                        }} type="text" value={address1} error={errors.address1} />
                                        <div className="error-text">{errors.address1}</div>
                                    </div>
                                </div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><Label className="label-text">Address 2</Label></div>
                                        <InputBox onChange={(e) => setAddress2(e.target.value)} type="text" value={address2} error={false} />
                                    </div>
                                </div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">City</Label></div>
                                        <InputBox onChange={(e) => {
                                            setCity(e.target.value);
                                            handleChange("city")
                                        }} type="text" value={city} error={errors.city} />
                                        <div className="error-text">{errors.city}</div>


                                    </div> <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">State/Province/Region</Label></div>
                                        <RegionDropdown
                                            country={country}
                                            countryValueType='short'
                                            value={state}
                                            classes='ant-input dropdown'
                                            onChange={(val) => {
                                                setState(val);
                                                handleChange("state")
                                            }
                                            }
                                        />
                                        <div className="error-text" style={{ marginTop: "4px" }}>{errors.state}</div>

                                    </div>
                                </div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Post/Zip Code</Label></div>
                                        <InputBox onChange={(e) => {
                                            setPostalCode(e.target.value);
                                            handleChange("postalCode")
                                        }} type="text" value={postalCode} error={errors.postalCode} />
                                        <div className="error-text " >{errors.postalCode}</div>


                                    </div> <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Country</Label></div>
                                        <CountryDropdown
                                            value={country}
                                            valueType='short'
                                            classes='ant-input dropdown'
                                            onChange={(val) => {
                                                setCountry(val);
                                                handleChange("country")
                                            }}
                                        />
                                        <div className="error-text" style={{ marginTop: "4px" }}>{errors.country}</div>

                                    </div>
                                </div>
                                <div className="column-form-wrapper">
                                    <div className="input-containers ">
                                        <div className="form-label-wrapper"><span>*</span><Label className="label-text">Phone</Label></div>
                                        <InputBox onChange={(e) => {
                                            setPhone(e.target.value);
                                            handleChange("phone")
                                        }} type="number" value={phone} error={errors.phone} />
                                        <div className="error-text">{errors.phone}</div>


                                    </div> <div className="input-containers ">
                                        <div className="form-label-wrapper"><Label className="label-text">Landline</Label></div>
                                        <InputBox onChange={(e) => setLandline(e.target.value)} type="number" value={landline} error={false} />

                                    </div>
                                </div>
                            </div>
                            <div className="create-content">
                                {companyDetails?.shopName}  is committed to respecting your privacy. By continuing you are accepting our Terms and Conditions, and our Privacy Policy.</div>
                            <div className="button-conatiner" onClick={() => {
                                if (validateForm() && !isLoading) {
                                    setIsLoading(true)
                                    handleRegister()
                                }
                            }}>{isLoading && <div className="loader-btn" />}Continue</div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal visibility={isError} type="failed" headerText="Sorry Something went wrong" subText="Account registration failed, Please try again"
                onPopupClose={() => { history.push("/account/login") }}
            />
            <Modal visibility={isError2} type="failed" headerText="Please login to continue" subText="Account already in our system, please click login to access our services"
                onPopupClose={() => { history.push("/account/login") }}
            />
            <Modal visibility={isSuccess} type="success" headerText="Account registration success" subText="Click ok to continue to your account" onPopupClose={() => {
                history.push("/")
            }} />
        </div>
    )
}
export default HospitalityCreateAccount