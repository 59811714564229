import Axios from "axios";
import apiEndpointPaths from "./apiEndpointPaths";
import { APIError, errorLogger } from "./errorHandlers";
import { generateTokenForNeutriPosRestaurantMenu, generateTokenForNewRestaurantMenu } from "./tokenGenerators";
import config from "react-global-configuration";
/*
  Get All Restaurant `Main Menus` and `Sub Menus` including `Products`
*/
export const getAllRestaurantMenuData = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData, ApplicationID: "632983961250b0105020d561", Type: config.get('Theme') === "Voucher-Default" ? "VOUCHER" : "MENU" };
    let token = await generateTokenForNewRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.loyaltyRegistryServer.getAllRestaurantMenuItems;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await Axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result, Currency, MenuCreationDate } = response.data;

    return Status ? { data: Result, currency: Currency, menuCreatedDate: MenuCreationDate } : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/*
  Get Restaurant Menu created Date
*/
export const getRestaurantMenuCreatedDate = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getRestaurantMenuCreatedDate;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await Axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Currency, MenuCreationDate } = response.data;

    return Status ? { currency: Currency, menuCreatedDate: MenuCreationDate } : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/*
  Get Restaurant `Main Menu` IDs
*/
export const getRestaurantMainMenuIDs = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getRestaurantMainMenuIDs;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await Axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result, Currency } = response.data;

    return Status ? { data: Result, currency: Currency } : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/*
  Get Restaurant `Main Menu` data by IDs
*/
export const getRestaurantMainMenuDataByIDs = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getRestaurantMainMenuDataByIDs;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await Axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result, Currency } = response.data;

    return Status ? { data: Result, currency: Currency } : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/*
  Get Restaurant `Sub Menu` data by IDs
*/
export const getRestaurantSubMenuDataByIDs = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getRestaurantSubMenuDataByIDs;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await Axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result, Currency } = response.data;

    return Status ? { data: Result, currency: Currency } : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Retrieve set of Addon Prices by sending their IDs
*/
export const getAddonProductPrices = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getAddonProductPrices;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await Axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get `Restaurant Menu Settings` data
*/
export async function getRestaurantMenuSettingsData(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getRestaurantMenuSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await Axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;
    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Place an order through `Restaurant Menu`
*/
export async function createRestaurantMenuOrder(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.addRestaurantMenuOrder;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await Axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get Delivery Distance by Postcode
*/
export async function getDeliveryDistanceByPostcode(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getDeliveryDistanceByPostcode;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await Axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get `Restaurant QR Code Settings` data
*/
export async function getRestaurantMenuQRCodeSettings(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getRestaurantQRCodeSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await Axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/*
  Get `Order Type List` data for Restaurant
*/
export async function getOrderTypeListDataForRestaurant(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPosRestaurantMenu();

    const apiReqUrl = apiEndpointPaths.neutriPosServer.getOrderTypeListData;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await Axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}