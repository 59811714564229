import Axios from "axios";
import globalValues from "./globalValues";

export async function generateTokenForRetailPax() {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: 'retailpacx',
    };
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );
    console.log(tokenResponse, "tokenResponse.data.token", apiObject)
    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
}
export async function generateTokenForVoucher() {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: 'voucher',
    };
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );
    console.log(tokenResponse, "tokenResponse.data.token", apiObject)
    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
}

export const generateEntitlementRegistry = async () => {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: "entitlementRegistry",
    };
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );

    return tokenResponse.data.token;
  } catch (error) {
    console.log("Error In Generating Token", error);
  }
}

/* 
  Generate token to access Restaurant Menu data in NeutriPOS
*/
export const generateTokenForNeutriPosRestaurantMenu = async () => {
  try {
    const payload = {
      isLoginServerRequest: false,
      serviceName: 'default'
    }

    const response = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      payload
    );

    return response.data.token;
  } catch (error) {
    console.error("Error in Generating Token to access Restaurant Menu data in NeutriPOS", error)
  }
}

export const generateTokenForNewRestaurantMenu = async () => {
  try {
    const payload = {
      isLoginServerRequest: false,
      serviceName: 'loyaltyregistry'
    }

    const response = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      payload
    );

    return response.data.token;
  } catch (error) {
    console.error("Error in Generating Token to access Restaurant Menu data in NeutriPOS", error)
  }
}
/* 
  Generate token to access `AppRegistry`
*/
export const generateTokenForAppRegistry = async () => {
  try {
    const payload = {
      isLoginServerRequest: false,
      serviceName: 'appRegistry'
    }

    const response = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      payload
    );

    return response.data.token;
  } catch (error) {
    console.error("Error in Generating Token to access App Registry", error)
  }
}

/* 
  Generate token to access `AppRegistry`
*/
export const generateTokenForEmailRegistry = async () => {
  try {
    const payload = {
      isLoginServerRequest: false,
      serviceName: 'emailRegistry'
    }

    const response = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      payload
    );

    return response.data.token;
  } catch (error) {
    console.error("Error in Generating Token to access Email Registry", error)
  }
}

/* 
  Generate token to access `SalesRegistry`
*/
export const generateTokenForSalesRegistry = async () => {
  try {
    const payload = {
      isLoginServerRequest: false,
      serviceName: 'salesRegistry'
    }

    const response = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      payload
    );

    return response.data.token;
  } catch (error) {
    console.error("Error in Generating Token to access Sales Registry", error)
  }
}