import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router'
import { Button, Stack, Typography, InputBox, } from 'witmeg-ui-system'
import "./HospitalityDefaultOrderPage.scss"
import { getOrderDetails } from "../../../RestaurantWidget/utilities/api/restaurantsService";
import { useDispatch, useSelector } from "react-redux";
import { setOrderTrackingData } from "../../../../../../../redux/resturantWidgetSlice";
import { LOCAL_STORAGE_ITEMS_NAMES } from "../../../RestaurantWidget/utilities/helper/helper";
import Footer from "../../components/Footer/Footer";

const HospitalityDefaultOrderTrackPage = () => {
    const [value, setValue] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)

    const history = useHistory()
    const dispatch = useDispatch()
    useEffect(() => {
        localStorage.removeItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_TRACK_ORDER)
    }, [])
    const storeOrderLocalData = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)) : null

    return (
        <div className="HospitalityDefaultOrderPage">
            <div className="Order-confirmation">
                <div className="Order-confirmation-header">
                    <div className="header-text-cont">Track your order</div>

                </div>
                <div className="eco-mb-16">

                    <div className="order-form">
                        <div className="order-form-container">
                            <div>
                                <div className="personal-header">Please enter Order ID</div>
                                <InputBox
                                    onChange={(e) => {
                                        setValue(e.target.value)
                                        setError(false)
                                    }}
                                    type="text"
                                    value={value}

                                />
                                {error && <Typography className="order-error">*Invalid Order ID. Please check the order number and try again.</Typography>}
                            </div>

                            <Stack className="eco-mb-36" >
                                <Button

                                    category={isLoading ? "load-text" : "icon-text"}
                                    variant="primary" className="custom-btn-order-2" onClick={() => {
                                        if (value !== "" && !isLoading) {
                                            setIsLoading(true)

                                            getOrderDetails(value, storeOrderLocalData.cloudLocationID).then((response) => {
                                                if (response) {
                                                    dispatch(setOrderTrackingData(response))
                                                    setIsLoading(false)
                                                    history.push("/order/status")

                                                }
                                            }).catch(() => {
                                                setIsLoading(false)
                                                setError(true)
                                            })
                                        }
                                    }} text="Track" />
                            </Stack>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    )
}
export default HospitalityDefaultOrderTrackPage