import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { Modal, Form, Button, Input, Divider } from 'antd';
import AccountLeftMenu from "./account-left-menu";
import config from 'react-global-configuration';
import { renderEmail } from 'postonents';
import VerificationEmail from '../email/VerificationEmail';
import _ from "lodash";
import AccountRegisterMartFury from "../themes/martfury/account-register.jsx";
import AccountRegisterApparelCasi from "../themes/apparel-casi/account-register.jsx";
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};



class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disabled: false };
  }
  state = { visible: false, submitState: "Register Now" };

  componentDidMount() {
    document.title = "Account Registration | " + config.get('companyPageTitle')
  }


  render() {
    const { submitState } = this.state;

    const onFinish = values => {
      this.setState({ disabled: true });
      //this.refs.btn.setAttribute("disabled", "disabled");
      const requestOptions = {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + config.get('LoginAuthorizationKey'),
        },
        body: JSON.stringify({ "Email": values.Email, "OrganizationID": config.get('OrganisationID') })
      };
      // console.log(requestOptions);
      fetch(config.get('API_URL_cust') + 'find', requestOptions)
        .then(response => response.json())
        .then(function (data) {
          //   console.log(data);


          if (data.Status === false) {
            sessionStorage.setItem('tmpEmail', values.Email);


            let date = new Date();
            let DigitCode = _.random(100000, 999999);
            values.ExpireDate = date.setHours(date.getHours() + 24, date.getMinutes(), 0, 0);
            let token = encrypt(JSON.stringify(DigitCode));

            const data = {
              verificationToken: token,
              companyPageTitle: config.get('companyPageTitle'),
              SenderEmail: config.get('SenderEmail'),
              companyLogoCDN: config.get('companyLogoCDN'),
              DigitCode: DigitCode,
              email: values.Email
            }
            const emailBody = renderEmail(VerificationEmail, { lang: 'en', data });

            sessionStorage.setItem('verificationtoken', token);
            sessionStorage.setItem('tmpEmail', values.Email);

            async function postData() {
              const ecoEmail = {
                "to": values.Email,
                "from": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                "subject": "Your verification code",
                "html": emailBody
              };
              const response = await fetch(config.get('backendURL') + 'mail/send', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(ecoEmail)
              })
              return response.json();
            }

            postData()
              .then(data => {
                //  console.log(data)
                if (data === "SUCCESS") {
                  Modal.success({
                    title: 'Please verify your account',
                    content: 'Verification email has been sent to ' + values.Email,
                    onOk() { window.location.href = "/account/register/verify/code" },
                  });
                }
                else {
                  Modal.error({
                    title: 'Oops!! Something wrong',
                    content: 'We are sorry, Something wrong with the registration process. Please try again later.',
                    onOk() { window.location.href = "/account/register" },
                  });
                }
              });

          } else {


            let secondsToGo = 3;
            const modal = Modal.success({
              title: 'You have already an account with us',
              content: `Please login to continue the shopping`,
            });
            const timer = setInterval(() => {
              secondsToGo -= 1;
              modal.update({
                content: `Please login to continue the shopping`,
              });
            }, 1000);
            setTimeout(() => {
              clearInterval(timer);
              modal.destroy();
              window.location.href = "/account/login"
            }, secondsToGo * 1000);


          }

        })


      //console.log('Received values of form: ', values);
    };

    function encrypt(data) {
      var CryptoJS = require("crypto-js");
      var key = 'U2FsdGVkX1y3pF1AfoHaMGdUJpg6nOMru98BUt';
      var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
      return cipherText;
    }
    return (

      <>
        {(() => {

          switch (config.get('Theme')) {
            case 'Apparel':
              return (<>

                <div class="ps-breadcrumb">
                  <div className="container">
                    <ul className="breadcrumb">
                      <li><a href="/">Home</a></li>
                      <li><a href="/account">Your Account</a></li>
                      <li><a href="/account/register">Register</a></li>
                    </ul>
                  </div>
                </div>

                <section className="ps-section--account">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4">
                        <AccountLeftMenu />
                      </div>
                      <div className="col-lg-8">
                        <div className="ps-section__right">

                          <AccountRegisterMartFury params={{ disabled: this.state.disabled }} onFinish={onFinish} />




                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </>)
            case 'Apparel-casi':
              return (<>



                <AccountRegisterApparelCasi params={{ disabled: this.state.disabled }} onFinish={onFinish} />





              </>)
            case 'Martfury':
              return (
                <div className='account-pages-wrapper'>
                  <div class="ps-breadcrumb">
                    <div className="container">
                      <ul className="breadcrumb">
                        <li><a href="/">Home</a></li>
                        <li><a href="/account">Your Account</a></li>
                        <li><a href="/account/register">Register</a></li>
                      </ul>
                    </div>
                  </div>

                  <section className="ps-section--account">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-4">
                          <AccountLeftMenu />
                        </div>
                        <div className="col-lg-8">
                          <div className="ps-section__right">

                            <AccountRegisterMartFury params={{ disabled: this.state.disabled }} onFinish={onFinish} />




                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                </div>)
            default:
              return (
                <div className='account-pages-wrapper'>
                  <Row>

                    <Col sm={12} >
                      <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/account">
                          Your Account
                        </Breadcrumb.Item> <Breadcrumb.Item href="/account/register">
                          Register
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>

                    {/* <Col sm={3}>
                      <AccountLeftMenu />
                    </Col> */}

                    <Col sm={4} style={{ marginLeft: "30px" }}>
                      <div className="fade alert alert-light show">



                        <Row className="justify-content-md-center">

                          <Col sm={12}>



                            <Divider orientation="left"><h5>REGISTER FOR ONLINE SHOPPING</h5></Divider>

                            <Form

                              name="basic"
                              initialValues={{ remember: true }}
                              onFinish={onFinish}
                            >



                              <Form.Item
                                name="Email"
                                label="Email"
                                rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
                              >
                                <Input placeholder="Please enter email" />
                              </Form.Item>





                              <Form.Item
                                name="messagebox"
                                label="Please wait"
                                hasFeedback
                                style={{ display: 'none' }}
                                validateStatus="validating"
                                help="The information is being validated..."
                              >

                              </Form.Item>


                              <Form.Item  >
                                <Button type="primary" htmlType="submit" ref="btn" block danger >
                                  {this.state.disabled ? 'Please wait...' : 'Register Now'}
                                </Button>
                              </Form.Item>
                            </Form>
                            Already have an account? <a href="/account/login">Sign in</a>  </Col>
                        </Row>





                      </div>

                    </Col>



                  </Row>
                </div>
              )
          }

        })()}
      </>
    )
  }
}

export default Registration;