import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuTabs from "../../components/Tabs/MenuTabs";
import Layout from "../../components/layouts/Layout";
import PopupWrapper from "../../../../ResturatnWidget/PopupWrapper";
import SkeletonMenuLoader from "../../components/SkeletonMenuLoader";
import { getRestaurantMenuUICustomizations } from "../../../../../utilities/restaurantMenuCustomizationsUtil";
import {
    setRestaurantMenuUICustomizations,
    fetchAllRestaurantMenuData,
} from "../../../../../../../redux/resturantWidgetSlice";
import OrderPicker from "../../components/OrderPicker/OrderPicker";
import "./Voucher.scss";
import Footer from "../../components/Footer/Footer";

const Voucher = ({ menu }) => {
    const itemRefs = useRef([]);
    const tabRefs = useRef([]);
    const dispatch = useDispatch();
    const { selectedOrderType } = useSelector((state) => state.resturantData);
    const companyDetails = useSelector((state) => state.companyData.companyDetails);
    const { menuData: { isLoading }, defualtSelectedLocation, organizationID } = useSelector((state) => state.resturantData);

    const [widgetState, setWidgetState] = useState({
        isPopup: null,
        layout: null,
        customizations: null,
        themeAvailable: false,
        isInitialLoading: true,
    });
    const [selectedItem, setSelectedItem] = useState(0);

    const initializeMenuCustomizations = useCallback(() => {
        localStorage.removeItem("QUANTITY")
        localStorage.removeItem("ITEM")
        if (menu) {
            const menuData = JSON.parse(menu);
            const menuCustomizationValues = getRestaurantMenuUICustomizations(menuData);
            dispatch(setRestaurantMenuUICustomizations({ uiCustomizations: menuCustomizationValues }));
            setWidgetState((prevState) => ({
                ...prevState,
                customizations: menuData,
                isPopup: menuData?.selectedCard === 'popup',
                layout: menuData?.selectedLayout,
                themeAvailable: menuData?.selctedTheme !== "removeTheme",
            }));
        }
    }, [menu, dispatch]);

    const fetchMenuData = useCallback(() => {
        dispatch(fetchAllRestaurantMenuData({
            CloudLocationID: defualtSelectedLocation.CloudLocationID,
            OrganizationID: organizationID,
        }));
        setWidgetState((prevState) => ({ ...prevState, isInitialLoading: false }));
    }, [dispatch, defualtSelectedLocation, organizationID]);

    useEffect(() => {
        initializeMenuCustomizations();
    }, [initializeMenuCustomizations]);

    useEffect(() => {
        fetchMenuData();
    }, [fetchMenuData]);

    useEffect(() => {
        if (isLoading) {
            setWidgetState((prevState) => ({ ...prevState, isInitialLoading: true }));
        }
    }, [isLoading]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + 120;
            itemRefs.current.forEach((item, index) => {
                if (item) {
                    const { top, height } = item.getBoundingClientRect();
                    const itemTop = top + window.scrollY;
                    if (itemTop <= scrollPosition && itemTop + height >= scrollPosition) {
                        tabRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                        setSelectedItem(index);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleSelect = (index) => {
        setSelectedItem(index);
        const selectedItemPosition = itemRefs.current[index].offsetTop - 40;
        window.scrollTo({ top: selectedItemPosition, behavior: 'smooth' });
        tabRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    };

    const getLayout = () => {
        if (window.innerWidth < 600) {
            return widgetState.layout === "layout1" || widgetState.layout === "layout3" ? "layout3" : widgetState.layout;
        }
        return widgetState.layout;
    };
    return (
        <div>
            <PopupWrapper isPopup={widgetState.isPopup}>
                {widgetState.isInitialLoading && isLoading ? (
                    <SkeletonMenuLoader />
                ) : (

                    isLoading ? (
                        <SkeletonMenuLoader />
                    ) : (
                        <div className="Voucherstyles">

                            <Layout layout={getLayout()} />
                            <Footer />
                        </div>
                    )
                )}
            </PopupWrapper>
        </div>
    );
};

export default Voucher;
