import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRestaurantMenuCartData } from '../themes/default/utilities/restaurantMenuUtil';
import _ from 'lodash';
import { LOCAL_STORAGE_ITEMS_NAMES, getLocalStorageData, setLocalStorageData } from '../themes/default/utilities/helper';
import { getAllRestaurantMenuData, getRestaurantMenuCreatedDate } from '../themes/default/utilities/apiRequests/neutriPosServerRequests';

export const syncRestaurantMenuCartDetails = createAsyncThunk(
  'restaurant-widget/sync-cart-data',
  async (_, thunkAPI) => {
    const cartData = await getRestaurantMenuCartData();
    return { cartData };
  }
);

export const fetchAllRestaurantMenuData = createAsyncThunk(
  'restaurant-widget/fetch-all-menu-data',
  async (data, thunkAPI) => {
    const { CloudLocationID, OrganizationID, mainMenuID } = data;

    const _localData = await getLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_ALL_MENU_DATA);
    const _currentState = thunkAPI.getState().resturantData;

    try {
      const _menuData = _localData ?? _currentState?.menuData?.storage ?? null;
      const _isMenuDataAvailable =
        ((_localData && _localData.data && _localData.menuCreatedDate) ||
          (_currentState.menuData &&
            _currentState.menuData.storage &&
            _currentState.menuData.storage.data &&
            _currentState.menuData.storage.data.length > 0 &&
            _currentState.menuData.storage.menuCreatedDate &&
            _currentState.menuData.storage.menuCreatedDate !== ''))
          ? true
          : false;

      let _data = {
        menuData: [],
        currency: _menuData ? _menuData.currency : 'GBP',
        mainMenus: [],
        menuCreatedDate: null,
        selectedMenuData: null,
        selectedMainMenuID: mainMenuID ?? null,
      };
      let _isMenuDateValid = _localData ? true : false;
      // if (_isMenuDataAvailable) {
      //   const _menuCreatedDateResponse = await getRestaurantMenuCreatedDate({ CloudLocationID, OrganizationID });
      //   console.log(_isMenuDateValid, _isMenuDataAvailable, _menuCreatedDateResponse)

      //   if (_menuCreatedDateResponse && _menuCreatedDateResponse.menuCreatedDate && _menuCreatedDateResponse.menuCreatedDate !== '') {
      //     const _currentDate = new Date(_menuData.menuCreatedDate).getTime();
      //     const _newDate = new Date(_menuCreatedDateResponse.menuCreatedDate).getTime();
      //     if (_currentDate === _newDate) {
      //       _isMenuDateValid = false;
      //       _data = { ..._data, menuCreatedDate: _menuCreatedDateResponse.menuCreatedDate };
      //     }
      //   }
      // }
      const _response = await getAllRestaurantMenuData({ CloudLocationID, OrganizationID });

      if (_response && _response.data) {
        if (_response.currency && _response.currency !== "") {
          _data = { ..._data, currency: _response.currency };
        }

        const _mainMenuData = _response.data.Menu.map(_item => ({
          menuID: _item.MenuID,
          localMenuID: 0,
          menuName: _item.Title.en,
          orderIndex: 0,
          showMenu: true,
          showOnline: true,
        }));
        _data = {
          ..._data,
          menuData: _response.data,
          mainMenus: _mainMenuData,
          menuCreatedDate: _response.menuCreatedDate,
        }

        const _newLocalData = {
          data: _response.data,
          mainMenus: _mainMenuData,
          menuCreatedDate: _response.menuCreatedDate,
          currency: _response?.currency ?? 'GBP',
        }

        setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_ALL_MENU_DATA, _newLocalData);

      } else {
        _data = {
          ..._data,
          menuData: _menuData.data,
          menuCreatedDate: _menuData.menuCreatedDate,
          mainMenus: _menuData.mainMenus,
        }
      }

      if (mainMenuID && mainMenuID !== '') {
        const _selectedMenuData = _.filter(_data.menuData.Categories, { MenuID: mainMenuID });
        _data = {
          ..._data,
          selectedMenuData: _selectedMenuData,
        };
      } else {
        const _selectedMenuData = _.filter(_data.menuData.Categories, { MenuID: _data.mainMenus[0].menuID });

        _data = {
          ..._data,
          selectedMainMenuID: _data.mainMenus[0].menuID,
          selectedMenuData: _selectedMenuData,
        };
      }

      if (_data.menuData && _data.menuData.Menu.length > 0) return _data;

      return thunkAPI.rejectWithValue('Data empty');
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resturantWidget = createSlice({
  name: 'Resutrant Widget',
  initialState: {
    hasResturantWidget: false,
    resturantCloudLocations: [],
    defualtSelectedLocation: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_SELECTED_LOC) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_SELECTED_LOC) : null,
    organizationID: '',
    orderID: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) : '',

    qrCodeURLs: {
      delivery: '',
      collection: '',
      table: '',
    },

    orderSettings: {
      /* 
        urlOrder: {
          orderType: null,
          cloudLocationID: null,
        }
      */
      urlOrder: null,
      settingsDefaultOrder: null,
      selected: {},
      all: [],
    },

    cartDetails: {
      currency: 'GBP',
      organizationID: '',
      items: [],
      subTotal: {},
      total: {},
      additions: {
        deliveryCharges: null,
        promoCode: '',
      },
      defaultOrderType: null,
      orderTypes: [],
      cloudLocationID: '',
    },

    currency: 'GBP',

    customerDetails: {
      firstName: '',
      lastName: '',
      mobileNo: '',
      emailAddress: '',
      customerAddress: {
        line1: '',
        line2: '',
        state: '',
        city: '',
        zipCode: '',
        country: '',
      },
      deliveryAddress: {
        line1: '',
        line2: '',
        state: '',
        city: '',
        zipCode: '',
        country: '',
      },
      isDeliveryAddressDifferent: true,
    },

    menuData: {
      storage: {
        data: [],
        menuCreatedDate: null,
        mainMenus: [],
      },
      selectedMainMenuID: null,
      selectedMainMenuData: null,
      isLoading: true,

      errorMessage: null,
      uiCustomizations: {},
      layout: {
        type: null,
        name: null,
      },
    },
    orderCartData: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_DATA)) !== undefined ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_DATA)) :
      {
        Items: [],
        FulfillmentIssues: null,
        IncludeSingleUseItems: false,
        RestrictedItems: {},
        RevisionID: "",
        SpecialInstructions: ""
      },
    orderDetails: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_ORDER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_ORDER)) : {},
    orderTrackingData: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_TRACK_ORDER) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_TRACK_ORDER)) : {},
    selectedOrderType: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).selectedOrderType : "",
    voucherData: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_VOUCHER_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_VOUCHER_DATA)) : null

  },

  reducers: {
    setHasResturantWidget: (state, action) => {
      const { hasResturantWidget } = action.payload || {};
      state.hasResturantWidget = hasResturantWidget;
    },

    setCloudLocationData: (state, action) => {
      const { resturantCloudLocations } = action.payload;
      state.resturantCloudLocations = resturantCloudLocations;
    },

    setRestaurantOrganizationID: (state, action) => {
      const { organizationID } = action.payload;
      state.organizationID = organizationID;
      const _newCartData = { ...state.cartDetails, organizationID }
      state.cartDetails = _newCartData;
      setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, _newCartData);
    },

    setDefaultCloudLocation: (state, action) => {
      const { defualtSelectedLocation } = action.payload;
      state.defualtSelectedLocation = defualtSelectedLocation;
      const _newCartData = { ...state.cartDetails, cloudLocationID: defualtSelectedLocation.CloudLocationID }
      state.cartDetails = _newCartData;
      setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA, _newCartData);
      setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_SELECTED_LOC, defualtSelectedLocation)

      if (state.orderSettings.all && state.orderSettings.all.length > 0 && !state.orderSettings.selected) {
        state.orderSettings.selected = _.find(state.orderSettings.all, {
          CloudLocationID: defualtSelectedLocation.CloudLocationID,
        });
      }

    },

    setRestaurantUrlOrder: (state, action) => {
      const { orderType, cloudLocationID } = action.payload || null;
      state.orderSettings = {
        ...state.orderSettings,
        urlOrder: { orderType, cloudLocationID },
      };
    },

    setOrderSettingsData: (state, action) => {
      const { orderSettings, settingsDefaultOrder } = action.payload || null;
      let _selected = null;
      const _all = orderSettings;

      if (state.defualtSelectedLocation && state.defualtSelectedLocation.CloudLocationID) {
        _selected = _.find(orderSettings, { CloudLocationID: state.defualtSelectedLocation.value });
      }

      state.orderSettings = {
        ...state.orderSettings,
        all: _all,
        selected: _selected,
        settingsDefaultOrder,
      };
    },

    setCustomerDetailsData: (state, action) => {
      const { customerData } = action.payload || null;
      state.customerDetails = { ...customerData };
    },

    setRestaurantMenuCurrency: (state, action) => {
      const { currency } = action.payload || null;
      state.currency = currency;
      state.cartDetails.currency = currency;
    },

    setRestaurantQRCodeURLs: (state, action) => {
      const { qrCodeURLs } = action.payload || null;
      state.qrCodeURLs = qrCodeURLs;
    },

    setRestaurantMainMenuData: (state, action) => {
      const { mainMenuData } = action.payload;
      state.menuData.mainMenus.data = mainMenuData;
      state.menuData.mainMenus.isLoading = false;

      if (!mainMenuData || mainMenuData.length === 0) {
        state.menuData.selectedMenu.subMenuData = [];
        state.menuData.selectedMenu.isLoading = false;
      } else {
        state.menuData.selectedMenu.index = 0;
      }
    },

    changeSelectedRestaurantMainMenu: (state, action) => {
      const { mainMenuID } = action.payload;
      state.menuData.selectedMainMenuID = mainMenuID;
    },

    setRestaurantMenuUICustomizations: (state, action) => {
      const { uiCustomizations } = action.payload;
      state.menuData.uiCustomizations = uiCustomizations;
    },
    setOrderData: (state, action) => {
      state.orderDetails = action.payload
      state.orderID = action.payload.OrderID
      state.orderCartData = action.payload.Carts;
      setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_ORDER, action.payload)
      setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_DATA, action.payload.Carts);
      localStorage.setItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID, action.payload.OrderID);
    },
    setOrderCartItems: (state, action) => {
      state.orderCartData.Items = action.payload
      setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_DATA, state.orderCartData);
    },
    setOrderEmpty: (state) => {
      state.orderCartData = {
        Items: [],
        FulfillmentIssues: null,
        IncludeSingleUseItems: false,
        RestrictedItems: {},
        RevisionID: "",
        SpecialInstructions: ""
      }
      state.orderID = ""
      state.orderDetails = {}
      localStorage.removeItem('date')
      localStorage.removeItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID)
      localStorage.removeItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_DATA)
      localStorage.removeItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_ORDER)
    },
    setOrderTrackingData: (state, action) => {
      state.orderTrackingData = action.payload
      setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_TRACK_ORDER, action.payload);

    },
    setSelectedOrderType: (state, action) => {
      state.selectedOrderType = action.payload
      let value = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA))
      let updated = { ...value, selectedOrderType: action.payload }
      localStorage.setItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA, JSON.stringify(updated))

    },
    setVoucherData: (state, action) => {
      state.voucherData = action.payload
      localStorage.setItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_VOUCHER_DATA, JSON.stringify(action.payload))

    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        syncRestaurantMenuCartDetails.fulfilled,
        (state, { payload }) => {
          state.cartDetails = payload.cartData;
        }
      )
      .addCase(
        fetchAllRestaurantMenuData.rejected,
        (state, { payload }) => {
          state.menuData.storage.data = [];
          state.menuData.storage.menuCreatedDate = null;
          state.menuData.isLoading = false;
        }
      )
      .addCase(
        fetchAllRestaurantMenuData.pending,
        (state) => {
          state.menuData.isLoading = true;
        }
      )
      .addCase(
        fetchAllRestaurantMenuData.fulfilled,
        (state, { payload }) => {
          state.menuData.isLoading = false;
          state.currency = payload.currency;
          state.menuData.storage = {
            data: payload.menuData,
            mainMenus: payload.mainMenus,
            menuCreatedDate: payload.menuCreatedDate,
          };
          state.menuData.selectedMainMenuData = payload.selectedMenuData;
          state.menuData.selectedMainMenuID = payload.selectedMainMenuID;
        }
      );
  },
});

export const {
  setHasResturantWidget,
  setCloudLocationData,
  setRestaurantOrganizationID,
  setDefaultCloudLocation,
  setRestaurantUrlOrder,
  setOrderSettingsData,
  setCustomerDetailsData,
  setRestaurantMenuCurrency,
  setRestaurantQRCodeURLs,
  setRestaurantMainMenuData,
  changeSelectedRestaurantMainMenu,
  setRestaurantMenuUICustomizations,
  setOrderData,
  setOrderCartItems,
  setOrderEmpty,
  setOrderTrackingData,
  setSelectedOrderType,
  setVoucherData
} = resturantWidget.actions;

export default resturantWidget.reducer;